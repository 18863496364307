import React from 'react'

import EmptyState from '../../EmptyState'
import { renderQuantity } from '../../../utils/helper'
import SubTitle from '../../typography/SubTitle'
import TableWrapper from '../../wrappers/TableWrapper'
import ViewWrapper from '../../wrappers/ViewWrapper'

function CommoditiesView({ profileSummaries }) {
  return (
    <ViewWrapper>
      <div className="mb-3 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <SubTitle color="gray">Commodities</SubTitle>
        </div>
      </div>
      {profileSummaries?.length > 0 ? (
        <div className="-my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 px-0.5 align-middle">
            <TableWrapper
              columns={['Name', 'Sold', 'Unsold', 'Total']}
              rows={profileSummaries?.map((profile) => {
                const { crop } = profile
                return [
                  profile?.profileName,
                  `${renderQuantity(profile?.sold?.quantity)} ${crop?.unit.toLowerCase() || ''}`,
                  `${renderQuantity(profile?.unsold?.quantity)} ${crop?.unit.toLowerCase() || ''}`,
                  `${renderQuantity(profile?.total?.quantity)} ${crop?.unit.toLowerCase() || ''}`
                ]
              })}
            />
          </div>
        </div>
      ) : (
        <EmptyState
          header="No commodities found."
          message="Get started by creating a commodity."
          disabled
        />
      )}
    </ViewWrapper>
  )
}

export default CommoditiesView
