import React from 'react'
import { createSearchParams, Link, useNavigate } from 'react-router-dom'
import { DocumentTextIcon } from '@heroicons/react/outline'
import { Field, Form, Formik } from 'formik'
import { Helmet } from 'react-helmet'
import Select from 'react-select'

import Button from '../../components/buttons/Button'
import Header from '../../components/Header'
import MultiSelect from '../../components/MultiSelect'
import { useReports } from '../../hooks/useReports'
import { reportKind } from '../../constants/reportKind'

const initialValues = {
  clients: [],
  productionYears: []
}

const initialValuesMonthlyReport = {
  clients: [],
  productionYear: ''
}

function AdminPage() {
  const [selectOptions] = useReports()
  const navigate = useNavigate()

  const onSubmit = ({ clients, productionYears, kind }) => {
    const isProductionYearsSelected = productionYears.length > 0
    const isClientsSelected = clients.length > 0

    const reportParams = {
      clients: isClientsSelected ? clients.join(',') : 'All-clients',
      kind
    }

    if (kind === reportKind.RiskManagement) {
      reportParams.productionYears = isProductionYearsSelected
        ? productionYears.join(',')
        : 'All-productionYears'
    } else {
      const [productionYear] = productionYears
      reportParams.productionYears = productionYear
    }

    navigate({
      pathname: 'reports',
      search: `?${createSearchParams(reportParams)}`
    })
  }

  const importInitialValues = {
    clients: [],
    productionYears: []
  }

  return (
    <>
      <Helmet>
        <title>Admin</title>
      </Helmet>
      <Header>Admin</Header>
      <p className="mb-4 text-xl font-semibold">Reports</p>
      <h2 className="mt-4 font-bold text-gray-500">Risk Management</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(value) => onSubmit({ ...value, kind: reportKind.RiskManagement })}
      >
        {({ setFieldValue }) => (
          <Form className="grid grid-cols-2 grid-rows-2 justify-center gap-4">
            <div className="mt-2 ">
              <label htmlFor="clients" className="text-sm font-medium text-gray-900">
                Select client
              </label>
              <Field
                name="clients"
                component={MultiSelect}
                setValue={setFieldValue}
                fieldName="clients"
                options={selectOptions?.clientsList}
                placeholder="All clients"
              />
            </div>
            <div className="mt-2">
              <label className="text-sm font-medium text-gray-900" htmlFor="productionYears">
                Select production year
              </label>
              <Field
                name="productionYears"
                component={MultiSelect}
                setValue={setFieldValue}
                fieldName="productionYears"
                options={selectOptions?.productionYears}
                placeholder="All production years"
              />
            </div>
            <div className="flex flex-shrink-0 flex-row">
              <div className="mr-2">
                <Button
                  classes="mt-3"
                  type="submit"
                  color="info"
                  label="Run report"
                  icon={<DocumentTextIcon className="h-5 w-5" aria-hidden="true" />}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <h2 className="font-bold text-gray-500">360 At A Glance</h2>
      <Formik
        initialValues={initialValuesMonthlyReport}
        onSubmit={({ clients, productionYears }) =>
          onSubmit({ clients, kind: reportKind.Glance, productionYears: [productionYears] })
        }
      >
        {({ values, setFieldValue }) => {
          const canRunReport = values.productionYears
          return (
            <Form className="grid grid-cols-2 grid-rows-2 justify-center gap-4">
              <div className="mt-2">
                <label htmlFor="clients" className="text-sm font-medium text-gray-900">
                  Select client
                </label>
                <Field
                  name="clients"
                  component={MultiSelect}
                  setValue={setFieldValue}
                  fieldName="clients"
                  options={selectOptions?.clientsList}
                  placeholder="All clients"
                />
              </div>
              <div className="mt-2">
                <label className="text-sm font-medium text-gray-900" htmlFor="productionYears">
                  Select production year
                </label>
                <Field
                  className="mt-1"
                  name="productionYears"
                  component={Select}
                  setValue={setFieldValue}
                  options={selectOptions?.productionYears}
                  placeholder="Production year"
                  onChange={({ value }) => setFieldValue('productionYears', value)}
                />
              </div>
              <div className="flex flex-shrink-0 flex-row">
                <div className="mr-2">
                  <span className={!canRunReport ? 'cursor-not-allowed opacity-80' : ''}>
                    <Button
                      disabled={!canRunReport}
                      classes="mt-3"
                      type="submit"
                      color="info"
                      label="Run report"
                      icon={<DocumentTextIcon className="h-5 w-5" aria-hidden="true" />}
                    />
                  </span>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
      <p className="mb-4 text-xl font-semibold">Imports</p>
      <Formik initialValues={importInitialValues} onSubmit={onSubmit}>
        {({ values }) => {
          const canImportCSV = values.clients[0] && values.productionYears[0]
          return (
            <Form className="grid grid-cols-2 grid-rows-2 justify-center gap-4">
              <div className="mt-2 ">
                <label className="text-sm font-medium text-gray-900" htmlFor="productionYears">
                  Select client
                </label>
                <Field
                  as="select"
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                  type="clients"
                  id="clients"
                  name="clients"
                  placeholder="All clients"
                >
                  <option defaultValue>Select client</option>
                  {selectOptions?.clientsList?.map((item) => (
                    <option value={item?.value}>{item?.label}</option>
                  ))}
                </Field>
              </div>
              <div className="mt-2">
                <label htmlFor="clients" className="text-sm font-medium text-gray-900">
                  Select production year
                </label>
                <Field
                  as="select"
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                  type="productionYears"
                  id="productionYears"
                  name="productionYears"
                  placeholder="All production years"
                >
                  <option defaultValue>Select production year</option>
                  {selectOptions?.productionYears.map((item) => (
                    <option value={item?.value}>{item?.label}</option>
                  ))}
                </Field>
              </div>
              <div className="flex flex-shrink-0 flex-row">
                <Link
                  className={!canImportCSV ? 'cursor-not-allowed opacity-80' : ''}
                  style={{
                    pointerEvents: !canImportCSV ? 'none' : 'all',
                    cursor: !canImportCSV ? 'none' : 'default'
                  }}
                  to={`/admin/imports/${values.clients}/${values?.productionYears}`}
                  state={{
                    name: selectOptions?.clientsList?.find(
                      (option) => option?.value === values.clients
                    )?.label
                  }}
                >
                  <Button
                    classes="mt-3 gap-2"
                    disabled={!canImportCSV}
                    color="info"
                    label="Import data from CSV"
                  />
                </Link>
              </div>
            </Form>
          )
        }}
      </Formik>
      <div className="grid grid-cols-2 grid-rows-2 justify-center gap-4">
        <div className="mt-2 ">
          <p className="mb-4 text-xl font-semibold">USDA Prices</p>
          <div className="flex flex-shrink-0 flex-row">
            <Link to="/admin/prices">
              <Button classes="mt-3 gap-2" color="info" label="Set USDA prices" />
            </Link>
          </div>
        </div>
        <div className="mt-2">
          <p className="mb-4 text-xl font-semibold">Futures Prices</p>
          <div className="flex flex-shrink-0 flex-row">
            <Link to="/admin/futures">
              <Button classes="mt-3 gap-2" color="info" label="Set Futures prices" />
            </Link>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 grid-rows-2 justify-center gap-4">
        <div className="mt-2">
          <p className="mb-4 text-xl font-semibold">Basis Default Valuations</p>
          <div className="flex flex-shrink-0 flex-row">
            <Link to="/admin/default-basis">
              <Button classes="mt-3 gap-2" color="info" label="Set Default Basis Valuations" />
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminPage
