import dayjs from 'dayjs'
import React, { Fragment } from 'react'

import ReportStats from '../../reportPrimitives/ReportStats'
import ReportSubTitle from '../../reportPrimitives/ReportSubtitle'
import ReportTable from '../../reportPrimitives/ReportTable'
import { renderMoney, renderNumber, renderPrice, renderQuantity } from '../../../utils/helper'
import ReportParagraph from '../../reportPrimitives/ReportParagraph'
import { reportKind } from '../../../constants/reportKind'
import units from '../../../constants/units'

const contractSummaryColumns = ['Name', 'Quantity', 'Price', 'Value']
const soldConstants = [
  { key: 'cash', value: 'Cash' },
  { key: 'basis', value: 'Basis' },
  { key: 'hta', value: 'HTA' },
  { key: 'npe', value: 'NPE' }
]

function DetailedProfileView({
  profileSummariesData,
  salesProfilesData,
  productionYear,
  reports,
  variant,
  kind
}) {
  return reports.map((report) => {
    const selectedCommodity = profileSummariesData?.getProfileSummaries.find(
      (profileSummary) => profileSummary.profileId === report.id
    )
    const crop = selectedCommodity?.crop

    const production =
      report.drylandAcres * report.drylandYield + report.irrigatedAcres * report.irrigatedYield

    const acres = report.drylandAcres + report.irrigatedAcres
    const avgYield = production / acres

    const storage = report.Storage?.reduce((sum, storage) => sum + storage.quantity, 0)
    const hedged = report.profilesWithRevenue.soldQuantity.reduce((sum, sold) => {
      if (kind === reportKind.Glance) {
        if (sold.kind !== 'SALE') return sum
        return sum + sold.quantity
      }

      return sum + (sold.subkind === 'BASIS' || sold.subkind === 'HTA' ? sold.quantity : 0)
    }, 0)

    const cashSales = salesProfilesData?.getSaleProfile?.filter(
      (saleProfile) => saleProfile.profile.cropId === report.cropId && saleProfile.kind === 'SALE'
    )
    const futureMonth = report.profilesWithRevenue?.futureMonth
    const basisAmount = report.profilesWithRevenue?.basisAmount
    if (!selectedCommodity) return null

    const currentPricePerUnit = selectedCommodity.unsold?.price || 0
    const currentPricePerAcre = (selectedCommodity.unsold?.value || 0) / (acres || 1)
    const currentValue = selectedCommodity.unsold?.value || 0

    const targetPricePerUnit = report.profilesWithRevenue?.targetPrice || 0
    const targetValue = report.profilesWithRevenue.targetPrice * selectedCommodity.unsold.quantity
    const targetPricePerAcre = targetValue / (acres || 1)

    const isAboveTarget = currentPricePerUnit > targetPricePerUnit

    return (
      <Fragment key={report.id}>
        <ReportSubTitle
          break
          variant={variant}
          pdfTextStyle={
            kind === reportKind.Glance
              ? {
                  color: '#fff',
                  backgroundColor: crop.colorCode,
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                  borderRadius: 6
                }
              : {}
          }
          title={
            <span
              className={
                kind === reportKind.Glance
                  ? 'flex items-center gap-2 rounded-md px-3 py-1 text-white'
                  : ''
              }
              style={kind === reportKind.Glance ? { background: crop.colorCode } : {}}
            >
              {selectedCommodity.profileName} {productionYear}
            </span>
          }
        />
        <ReportStats
          variant={variant}
          values={[
            ['Acres', renderNumber(acres)],
            ['Yield (avg)', renderNumber(avgYield)],
            ['Production', renderNumber(production)],
            ['Storage', renderNumber(storage)],
            [
              `${units[crop?.unit] || ''} Committed/Sold`,
              renderNumber(hedged),
              <>{renderNumber((hedged / production) * 100)}% of prod</>
            ],
            kind !== reportKind.Glance && [
              'Target Price',
              renderPrice(report.profilesWithRevenue.targetPrice)
            ],
            kind !== reportKind.Glance && [
              <>
                Board {futureMonth ? `(${futureMonth.month.slice(0, 3)} ${futureMonth.year})` : ''}{' '}
              </>,
              futureMonth ? renderPrice(selectedCommodity.unsold?.price) : '',
              basisAmount ? <>basis {renderMoney(basisAmount)}</> : ''
            ]
          ].filter(Boolean)}
        />
        <ReportTable
          variant={variant}
          columns={['Value', `Per ${crop?.unit?.toLowerCase() || 'unit'}`, 'Per acre', 'Gross']}
          rows={[
            [
              'Current',
              renderPrice(currentPricePerUnit),
              renderMoney(currentPricePerAcre),
              renderMoney(currentValue)
            ],
            [
              'Target',
              renderPrice(targetPricePerUnit),
              renderMoney(targetPricePerAcre),
              renderMoney(targetValue)
            ],
            [
              isAboveTarget ? (
                <span className="text-green-500">Above Target</span>
              ) : (
                <span className="text-red-500">Below Target</span>
              ),
              <span className={isAboveTarget ? 'text-green-500' : 'text-red-500'}>
                {currentPricePerUnit - targetPricePerUnit > 0 ? '+' : ''}
                {renderPrice(currentPricePerUnit - targetPricePerUnit)}
              </span>,
              <span className={isAboveTarget ? 'text-green-500' : 'text-red-500'}>
                {currentPricePerAcre - targetPricePerAcre > 0 ? '+' : ''}
                {renderMoney(currentPricePerAcre - targetPricePerAcre)}
              </span>,
              <span className={isAboveTarget ? 'text-green-500' : 'text-red-500'}>
                {currentValue - targetValue > 0 ? '+' : ''}
                {renderMoney(currentValue - targetValue)}
              </span>
            ]
          ]}
        />

        {kind !== reportKind.Glance && (
          <ReportTable
            variant={variant}
            title={<>Cash Summary - {selectedCommodity.profileName}</>}
            columns={contractSummaryColumns}
            rows={[
              [
                'Total',
                `${renderQuantity(selectedCommodity.total?.quantity)} ${
                  crop?.unit.toLowerCase() || ''
                }`,
                renderPrice(selectedCommodity.total?.price),
                renderMoney(selectedCommodity.total?.value)
              ],
              [
                'Sold',
                `${renderQuantity(selectedCommodity.sold?.quantity)} ${
                  crop?.unit.toLowerCase() || ''
                }`,
                renderPrice(selectedCommodity.sold?.price),
                renderMoney(selectedCommodity.sold?.value)
              ],
              ...soldConstants.map((item) => [
                <ReportParagraph variant={variant} text={item?.value} />,
                <>
                  {renderQuantity(selectedCommodity[item?.key]?.quantity)}{' '}
                  {crop?.unit.toLowerCase()}
                </>,
                renderPrice(selectedCommodity[item?.key]?.price),
                renderMoney(selectedCommodity[item?.key]?.value)
              ]),
              [
                'Unsold',
                <>
                  {renderQuantity(selectedCommodity.unsold?.quantity)} {crop?.unit.toLowerCase()}
                </>,
                renderPrice(selectedCommodity.unsold?.price),
                renderMoney(selectedCommodity.unsold?.value)
              ]
            ]}
          />
        )}
        {!!cashSales?.length && (
          <ReportTable
            wrap
            break
            variant={variant}
            title={
              kind === reportKind.Glance
                ? `${selectedCommodity.profileName} - Grain Contracts`
                : `Cash Sales - ${selectedCommodity.profileName}`
            }
            columns={[
              'Buyer',
              'Type',
              'Fut. Month',
              'Price',
              'Quantity',
              'Expected Revenue',
              'Delivery',
              'Delivered',
              'Sale Date',
              'Contract Number'
            ]}
            rows={cashSales.map((sale) => [
              sale.buyer?.name,
              sale.subkind?.replace('_SALE', ''),
              sale.futuresMonth
                ? `${sale.futuresMonth.split(' ')[0].slice(0, 3)} ${
                    sale.futuresMonth.split(' ')[1]
                  }`
                : '',
              sale.subkind !== 'NPE' && renderPrice(sale.displayPrice),
              <>
                {renderQuantity(sale.quantity)}&nbsp;{crop?.unit?.toLowerCase()}
              </>,
              sale.subkind !== 'NPE' && renderMoney(sale.expectedRevenue),
              `${dayjs(sale.deliveryStartDate).format('MM/DD/YYYY')} -
                                    ${dayjs(sale.deliveryEndDate).format('MM/DD/YYYY')}`,
              sale.delivered ? 'Yes' : 'No',
              dayjs(sale.saleDate).format('MM/DD/YYYY'),
              sale.referenceNumber
            ])}
          />
        )}
      </Fragment>
    )
  })
}

export default DetailedProfileView
