/* eslint-disable no-unsafe-optional-chaining */
import Papa from 'papaparse'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { ArrowLeftIcon } from '@heroicons/react/outline'

import AlertBox from '../../components/AlertBox'
import Button from '../../components/buttons/Button'
import GET_BUYERS from '../../gql/queries/getBuyers'
import GET_SALES_PROFILES from '../../gql/queries/getSalesProfile'
import Header from '../../components/Header'
import IMPORT_CSV from '../../gql/mutations/importCsv'
import PROFILES from '../../gql/queries/Profiles'
import ScrollButton from '../../components/buttons/ScrollButton'
import { USER_ROUTES } from '../../constants/routes'

const commonConfig = { delimiter: ',' }
const csvOptions = ['Sale', 'Crop Profile']

function ImportsPage() {
  const [file, setFile] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [parsedString, setParsedString] = useState()
  const [disableLeftScroll, setDisableLeftScroll] = useState(false)
  const [disableRightScroll, setDisableRightScroll] = useState(false)
  const [isDataVisible, setIsDataVisible] = useState(false)
  const [importCsv] = useMutation(IMPORT_CSV)
  const { id, productionYearId } = useParams()
  const fileReader = new FileReader()
  const location = useLocation()

  // const checkCsvType = (csvArray) => {
  //   const firstRow = csvArray[0] || array[0]
  //   if (firstRow) {
  //     const isCrop = !!firstRow['Dry acres']
  //     if (isCrop) {
  //       setSelectedOption('Crop Profile')
  //     } else setSelectedOption('Sale')
  //   }
  // }

  const onImport = async () => {
    convert(file)
    await toast.promise(
      importCsv({
        variables: {
          input: {
            data: parsedString,
            type: selectedOption?.replace(' ', '')?.toUpperCase(),
            clientId: id,
            year: parseInt(productionYearId, 10),
            csvId: file?.name
          }
        },
        refetchQueries: [GET_BUYERS, PROFILES, GET_SALES_PROFILES]
      }),
      {
        loading: 'Importing data...',
        success: 'Data imported successfully.',
        error: (err) =>
          err?.graphQLErrors[0]?.message || 'Something went wrong while importing the data.'
      },
      {
        duration: 5000
      }
    )
  }

  const handleOnChange = (e) => {
    setFile(e.target.files[0])
    setIsDataVisible(false)
    convert(e.target.files[0])
    // checkCsvType(array)
  }

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',')
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n')

    const array = csvRows.map((i) => {
      const values = i.split(',')
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index]
        return object
      }, {})
      return obj
    })
    array.slice(0, -1)
    // checkCsvType(array)
    // setArray(array)
  }

  const convert = (file) => {
    if (file) {
      fileReader.onload = (event) => {
        Papa.parse(event.target.result, {
          ...commonConfig,
          header: true,
          complete: (result) => {
            setParsedString(result.data)
          }
        })
        const text = event.target.result
        csvFileToArray(text)
      }

      fileReader.readAsText(file)
    }
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
    convert(file)
  }

  const ref = useRef(null)
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset
    if (ref?.current?.scrollLeft === 0) {
      setDisableLeftScroll(true)
    } else {
      setDisableLeftScroll(false)
    }

    // eslint-disable-next-line no-unsafe-optional-chaining
    if (ref?.current?.scrollLeft + ref?.current?.clientWidth >= ref?.current?.scrollWidth) {
      setDisableRightScroll(true)
    } else {
      setDisableRightScroll(false)
    }
  }

  useEffect(() => {
    if (ref?.current?.scrollLeft === 0) {
      setDisableLeftScroll(true)
    }

    if (ref?.current?.scrollLeft + ref?.current?.clientWidth >= ref?.current?.scrollWidth) {
      setDisableRightScroll(true)
    }
  }, [])

  const fileRef = useRef()

  const onReset = (e) => {
    setSelectedOption(undefined)
    // setArray([])
    setFile(undefined)
    setParsedString(undefined)
    e?.form?.reset()
    fileRef.current.value = null
    setIsDataVisible(false)
  }
  const navigate = useNavigate()

  const headerKeys = Object.keys(parsedString ? Object.assign({}, ...parsedString) : {})
  return (
    <>
      <Helmet>
        <title>Imports</title>
      </Helmet>
      <Header>Imports</Header>
      <Button
        label="Back to Admin"
        classes="mr-6 items-center mb-4"
        icon={<ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />}
        onClick={() => navigate(USER_ROUTES.ADMIN)}
      />
      {productionYearId === 'unde' || productionYearId === 'undefined' ? (
        <AlertBox message="No data found." />
      ) : (
        <>
          <p className="text-xl font-semibold text-slate-800">{`${location?.state?.name} (${productionYearId})`}</p>

          <div style={{ textAlign: 'center' }}>
            <form onReset={onReset}>
              <div className="flex flex-col">
                <div className="mr-auto flex flex-col py-2">
                  <p className="mb-2 mr-auto mt-2 font-bold ">Upload CSV file</p>
                  <input
                    ref={fileRef}
                    type="file"
                    id="csvFileInput"
                    accept=".csv"
                    onChange={handleOnChange}
                  />
                </div>

                {file && (
                  <div className="mr-auto">
                    <p className="mb-2 font-bold">Select CSV file type</p>
                    <select
                      className="ml-[-10px] mb-4 rounded-md"
                      value={selectedOption}
                      onChange={({ target: { value } }) => {
                        setSelectedOption(value)
                      }}
                    >
                      {csvOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div className="mt-2 flex flex-row">
                  {file && (
                    <Button
                      classes="mr-2"
                      label={`${isDataVisible ? 'Hide' : 'Show'} CSV Data`}
                      onClick={(e) => {
                        handleOnSubmit(e)
                        setIsDataVisible(!isDataVisible)
                      }}
                    />
                  )}
                  {file && <Button disabled={!file} label="Import data" onClick={onImport} />}
                  {file && (
                    <Button
                      classes="ml-2 text-white bg-red-600 hover:bg-red-700 focus:bg-red-700"
                      // color="neutral"
                      color="danger"
                      label="Remove File"
                      onClick={() => {
                        onReset()
                      }}
                    />
                  )}
                </div>
              </div>
            </form>

            <br />
            {isDataVisible && parsedString && (
              <div className="mt-3 flex flex-row">
                {selectedOption === 'Sale' && (
                  <ScrollButton
                    direction="left"
                    onClick={() => scroll(-200)}
                    disabled={disableLeftScroll}
                  />
                )}
                <div
                  ref={ref}
                  className="overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                >
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="border-r-gray-200 bg-gray-50">
                      <tr key="header" className="border-r-gray-200">
                        {headerKeys?.map((key) => (
                          <th className="border border-solid border-r-gray-200 p-2">{key}</th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {parsedString?.slice(0, -1)?.map((item) => (
                        <tr key={item.id}>
                          {Object.values(item).map((val) => (
                            <td className="mb-auto border border-solid border-zinc-200 p-2">
                              {val}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {selectedOption === 'Sale' && (
                  <ScrollButton
                    direction="right"
                    onClick={() => scroll(200)}
                    disabled={disableRightScroll}
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default ImportsPage
