/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'

import BasisValuationModal from '../../modals/BasisValuationModal'
import EmptyState from '../../EmptyState'
import GET_BASIS_VALUATIONS from '../../../gql/queries/getBasisValuations'
import PieChart from '../../charts/PieChart'
import SubTitle from '../../typography/SubTitle'
import TableWrapper from '../../wrappers/TableWrapper'
import ViewWrapper from '../../wrappers/ViewWrapper'
import { renderMoney, renderQuantity, renderPrice } from '../../../utils/helper'

const contractSummaryColumns = ['Name', 'Quantity', 'Price', 'Value']
const soldConstants = [
  { key: 'cash', value: 'Cash' },
  { key: 'basis', value: 'Basis' },
  { key: 'hta', value: 'HTA' },
  { key: 'npe', value: 'NPE' }
]

function BasisValuationButton({ selectedProfileSummary, name, id }) {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = () => {
    setIsOpen(false)
  }

  const { data: basisData, refetch: refetchBasis } = useQuery(GET_BASIS_VALUATIONS, {
    variables: {
      input: {
        profileId: selectedProfileSummary?.profileId,
        prodYear: name
      }
    },
    skip: !selectedProfileSummary?.profileId
  })

  let averageBasis = 0
  if (basisData?.getBasisValuations?.length === 0) {
    averageBasis = '$0.0000 basis'
  } else if (basisData?.getBasisValuations?.length === 1) {
    averageBasis = `$${(basisData?.getBasisValuations[0].basisAmount).toFixed(4)} basis`
  } else {
    averageBasis = 'basis valuation'
  }

  return (
    <>
      <div className="flex items-center">
        {selectedProfileSummary.production < selectedProfileSummary.sold.quantity
          ? 'Overcommitted'
          : 'Unsold'}
        <button
          type="button"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          className="mx-2 rounded-md border border-gray-300 bg-gray-100 py-1 px-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
        >
          {averageBasis}
        </button>
      </div>

      <BasisValuationModal
        isOpen={isOpen}
        basisMode
        profile={selectedProfileSummary}
        closeModal={closeModal}
        prodYear={name}
        clientId={id}
        data={basisData}
        averageBasis={averageBasis}
        refetchBasis={refetchBasis}
      />
    </>
  )
}

function ProfileSummary({ selectedProfileSummary, crop, productionYear, clientId }) {
  return (
    <div className="mt-2 flex-grow">
      {selectedProfileSummary ? (
        <div key={selectedProfileSummary.profileId}>
          <TableWrapper
            columns={contractSummaryColumns}
            rows={[
              [
                selectedProfileSummary.profileName,
                `${renderQuantity(selectedProfileSummary.total?.quantity)} ${
                  crop?.unit.toLowerCase() || ''
                }`,
                renderPrice(selectedProfileSummary.total?.price),
                renderMoney(selectedProfileSummary.total?.value)
              ],
              [
                'Sold',
                `${renderQuantity(selectedProfileSummary.sold?.quantity)} ${
                  crop?.unit.toLowerCase() || ''
                }`,
                renderPrice(selectedProfileSummary.sold?.price),
                renderMoney(selectedProfileSummary.sold?.value)
              ],
              ...soldConstants.map((item) => [
                <div key={item?.key} className="pl-[25px] text-[gray]">
                  {item?.value}
                </div>,
                <>
                  {renderQuantity(selectedProfileSummary[item?.key]?.quantity)}{' '}
                  {crop?.unit.toLowerCase() || ''}
                </>,
                renderPrice(selectedProfileSummary[item?.key]?.price),
                renderMoney(selectedProfileSummary[item?.key]?.value)
              ]),
              [
                <BasisValuationButton
                  name={productionYear}
                  id={clientId}
                  selectedProfileSummary={selectedProfileSummary}
                />,
                <>
                  {renderQuantity(selectedProfileSummary.unsold?.quantity)}{' '}
                  {crop?.unit.toLowerCase() || ''}
                </>,
                renderPrice(selectedProfileSummary.unsold?.price),
                renderMoney(selectedProfileSummary.unsold?.value)
              ]
            ]}
          />
        </div>
      ) : (
        'No Data'
      )}
    </div>
  )
}

function ContractsView({ loading, profileSummaries }) {
  const { id, productionYearId } = useParams()
  const name = Number(productionYearId)

  const initialValues = {
    commodity: profileSummaries?.[0]?.profileId
  }

  return (
    <ViewWrapper>
      <SubTitle color="gray">Contract Summary</SubTitle>
      <div className="flex items-center justify-between">
        {profileSummaries?.length > 0 ? (
          <Formik initialValues={initialValues}>
            {({ setFieldValue, values }) => {
              const selectedProfileSummary = profileSummaries.find(
                (commodity) => commodity.profileId === values.commodity
              )

              const crop = selectedProfileSummary?.crop

              return (
                <>
                  <div className="h-3/4 w-3/4 self-stretch">
                    <Form>
                      <div>
                        <Field
                          as="select"
                          name="commodity"
                          className="mt-1 block w-fit rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                          onChange={(event) => {
                            setFieldValue('commodity', event.target.value)
                          }}
                        >
                          {profileSummaries?.map((commodity) => (
                            <option key={commodity.id} value={commodity.profileId}>
                              {commodity?.profileName} {commodity?.isDryland ? '(dry)' : '(irr)'}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </Form>
                    <ProfileSummary
                      selectedProfileSummary={selectedProfileSummary}
                      crop={crop}
                      productionYear={name}
                      clientId={id}
                    />
                  </div>
                  {!loading && selectedProfileSummary && (
                    <div className="h-72 w-72">
                      {selectedProfileSummary.production < selectedProfileSummary.sold.quantity ? (
                        <div className="flex h-full w-full items-center justify-center rounded-md bg-gray-200">
                          Overcommitted
                        </div>
                      ) : (
                        <PieChart commodityData={selectedProfileSummary} />
                      )}
                    </div>
                  )}
                </>
              )
            }}
          </Formik>
        ) : (
          <EmptyState header="No contract summary data found." disabled />
        )}
      </div>
    </ViewWrapper>
  )
}

export default ContractsView
