/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unsafe-optional-chaining */
import React from 'react'
import { Helmet } from 'react-helmet'

import AdminReportsPage from './AdminReportsPage'
import ImportsPage from './ImportsPage'
import LayoutWrapper from '../../components/layouts/LayoutWrapper'
import NavTabs from '../../components/NavTabs'
import { ADMIN_ROUTES } from '../../constants/routes'
import USDAPricesPage from './USDAPricesPage'

const tabs = [
  {
    name: 'Reports',
    href: ADMIN_ROUTES.REPORTS,
    value: 'REPORTS'
  },
  {
    name: 'Import',
    href: ADMIN_ROUTES.IMPORTS,
    value: 'IMPORTS'
  },
  {
    name: 'Prices',
    href: ADMIN_ROUTES.PRICES,
    value: 'PRICES'
  }
]

function Tab({ tabID }) {
  let tabElement
  if (tabID === ADMIN_ROUTES.REPORTS) {
    tabElement = <AdminReportsPage />
  } else if (tabID === ADMIN_ROUTES.IMPORTS) {
    tabElement = <ImportsPage />
  } else if (tabID === ADMIN_ROUTES.PRICES) {
    tabElement = <USDAPricesPage />
  }

  return <NavTabs tabs={tabs} tabElement={tabElement} />
}

export default function AdminDetailsPage({ tab }) {
  return (
    <>
      <Helmet>
        <title>Admin Details</title>
      </Helmet>

      <LayoutWrapper>
        <Tab tabID={tab} />
      </LayoutWrapper>
    </>
  )
}
